export default {
  type: "products",
  name: null,
  excerpt: '',
  price: null,
  taxes: [],
  picture: null,
  gallery: [],
  relationshipNames: ["organization", "allowedLocations"],
  organization: {
    type: "organizations",
    id: null,
  },
  allowedLocations: [],
};
