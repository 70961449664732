<template>
  <div class="form-group">
    <label class="form-control-label"> {{ label }} </label>
    <div class="card-img pb-4">
      <div
        v-for="(image, key) in gallery"
        :key="key"
        class="gallery-image-container mr-4 mb-2 card-img"
      >
        <img :src="`${image}`" class="gallery-image" />
        <base-button
          type="button"
          class="gallery-image-remove-btn btn btn-sm btn-danger"
          @click="removeImage(key)"
        >
          <span>
            <i class="fa fa-times" />
          </span>
        </base-button>
      </div>

      <base-button
        type="button"
        class="btn btn-sm mr-2 mb-2 gallery-add-image-btn"
      >
        <label for="imageInputGallery">
          <i v-if="loading" class="fas fa-spinner fa-spin fa-10x" />
          <i v-else class="fas fa-plus fa-10x" />
        </label>
        <input
          id="imageInputGallery"
          ref="imageInput"
          accept="image/*"
          type="file"
          style="display: none"
          @input="onFileChange"
        />
      </base-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "gallery-selector",

  props: {
    defaultGallery: {
      type: Array,
      default: null,
      description: "Default gallery to diplay, should be model value",
    },
    label: {
      type: String,
      default: null,
      description: "Label of the field",
    },
    ressource_name: {
      type: String,
      default: null,
      description:
        "Ressource name to be uploaded with, must be allowed in the api",
    },
    ressouce_id: {
      type: String,
      default: null,
      description:
        "Ressource id to be uploaded with, must be allowed in the api",
    },
    field: {
      type: String,
      default: null,
      description:
        "Ressource field to be uploaded with, must be allowed in the api",
    },
  },

  setup() {},

  data() {
    return {
      gallery: [],
      loading: false,
    };
  },

  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.uploadImage(files[0]);
    },

    async uploadImage(file) {
      this.loading = true;
      if (file) {
        try {
          await this.$store.dispatch("files/upload", {
            image: file,
            ressource_name: this.ressource_name,
            ressource_id: this.ressource_id,
            field: this.field,
          });
          const image_url = await this.$store.getters["files/url"];
          this.gallery = [...this.gallery, image_url];
          this.$emit("galleryChanged", this.gallery);
          this.$refs.imageInput.value = null;
          this.loading = false;
        } catch (error) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
          this.loading = false;
        }
      }
    },

    removeImage(key) {
      this.gallery = this.gallery.filter((_, idx) => key !== idx);
      this.$emit("galleryChanged", this.gallery);
    },
  },

  watch: {
    defaultGallery(defaultGallery) {
      this.gallery = defaultGallery;
    },
  },
};
</script>

<style scoped>
.gallery-image-container {
  width: 200px;
  height: 200px;
  border: 1px solid #ccc;
  display: inline-block;
  position: relative;
}
.gallery-image {
  width: 100%;
  height: 100%;
}
.gallery-image-remove-btn {
  position: absolute;
  left: -20px;
  top: -10px;
}
.gallery-add-image-btn {
  width: 200px;
  height: 200px;
}
.gallery-add-image-btn label {
  width: 100%;
  cursor: pointer;
}
</style>
